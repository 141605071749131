<template>
    <b-container fluid v-if="dispatches.length > 0">
        <div class="row">
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="company">Firma:</label>
                            <select class="form-control form-control-sm" v-model="params.company_id">
                                <option value="">-- Wähle Firma --</option>
                                <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="company">Kunde:</label><br>
                            <button class="btn btn-sm btn-primary form-control form-control-sm" @click="selectCustomer" :disabled="params.company_id == '' || params.company_id == null">Kunde wählen</button>
                        </div> 
                    </div>
                </div>    
            </div>

            <div class="col-md-1">
                <div class="form-group">
                    <label for="company">Brutto / Netto:</label><br>
                    <toggle-button
                        v-model="params.tax"
                        :labels="{checked: 'Brutto', unchecked: 'Netto'}"
                        :switch-color="{checked: 'green', unchecked: 'red'}" :width="100" :height="25" :font-size="14" :sync="true"
                    />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label for="company">Gültig bis:</label><br>
                    <input type="date" class="form-control form-control-sm" v-model="order.expires_at" />
                </div> 
            </div>
        </div>

        <div class="row">
            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rechnungsadresse</h3>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.billing_id">
                                <option v-for="address in params.customer.addresses" :key="address.id" :value="address.id">
                                    {{ address.first_name }} {{ address.last_name }} /
                                    <template v-if="address.type == 'address'">
                                    {{ address.street }} {{ address.house_number }} /
                                    </template>
                                    <template v-if="address.type == 'packstation'">
                                    {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                    </template>
                                    <template v-if="address.type == 'postfiliale'">
                                    {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                    </template>
                                    {{ address.zipcode }} {{ address.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Lieferadresse</h3>
                        <div class="card-tools">
                            <button v-if="notEmptyObject(params.customer)" class="btn btn-sm btn-secondary" @click="openAddressModal">Neue Adresse</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Rechnungsanschrift verwenden?</label>
                            <select class="form-control form-control-sm" v-model="params.useBillingAddress">
                                <option value="true">Ja</option>
                                <option value="false">Nein</option>
                            </select>
                        </div>

                        <div class="form-group" v-if="params.useBillingAddress == 'false'">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.shipping_id">
                                <option v-for="address in params.customer.addresses" :key="address.id" :value="address.id">
                                    {{ address.first_name }} {{ address.last_name }} /
                                     <template v-if="address.type == 'address'">
                                    {{ address.street }} {{ address.house_number }} /
                                    </template>
                                    <template v-if="address.type == 'packstation'">
                                    {{ address.post_customer_number }} / Packstation {{ address.post_number }} /
                                    </template>
                                    <template v-if="address.type == 'postfiliale'">
                                    {{ address.post_customer_number }} / Postfiliale {{ address.post_number }} /
                                    </template>
                                    {{ address.zipcode }} {{ address.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Zahlungsart</h3>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.payment_id">
                                <option v-for="payment in payments" :key="payment.id" :value="payment.id">
                                    {{ payment.description }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Bestellinformationen</h3>
                    </div>
                    <div class="card-body">
                        
                         <template v-if="params.tax == true">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span>Zwischensumme (Positionen): {{ calculateSubTotalBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateDiscountBrutto() > 0">
                                        <div class="col-md-12">
                                            <span style="color: red">abzgl. Rabatt: {{ calculateDiscountBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateDepositReturnBrutto() > 0">
                                        <div class="col-md-12">
                                            <span style="color: red">abzgl. Pfandrückgabe: {{ calculateDepositReturnBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateDeposit() > 0">
                                        <div class="col-md-12">
                                            <span>zzgl. Pfand: {{ calculateDeposit() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateFragile() > 0">
                                        <div class="col-md-12">
                                            <span>zzgl. Bruchzuschlag: {{ calculateFragile() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateCargo() > 0">
                                        <div class="col-md-12">
                                            <span>zzgl. Sperrgutzuschlag: {{ calculateCargo() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateCooling() > 0">
                                        <div class="col-md-12">
                                            <span>zzgl. Kühlzuschlag: {{ calculateCooling() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <span>zzgl. Versandkosten: {{ calculateShippingNetto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="mt-3 row">
                                        <div class="col-md-12">
                                            <hr>
                                            <span>Gesamtsumme (Netto): {{ calculateTotalNetto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-for="tax in calculateTaxesBrutto()" :key="tax.tax">
                                        <div class="col-md-12">
                                            <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.actualTax | toCurrency }}</em></span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <span><strong>Gesamtsumme (Brutto): {{ calculateTotalBrutto() | toCurrency }}</strong></span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateTotalVoucher() > 0">
                                        <div class="col-md-12">
                                            <span style="color: red">abzgl. Gutschein: {{ calculateTotalVoucher() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateTotalVoucher() > 0">
                                        <div class="col-md-12">
                                            <span><strong>Zahlbetrag (Brutto): {{ calculateTotalBrutto() - calculateTotalVoucher() | toCurrency }}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="params.tax == false">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <span>Zwischensumme (Positionen): {{ calculateSubTotalNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateDiscountNetto() > 0">
                                    <div class="col-md-12">
                                        <span style="color: red">abzgl. Rabatt: {{ calculateDiscountNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateDepositReturnNetto() > 0">
                                    <div class="col-md-12">
                                        <span style="color: red">abzgl. Pfandrückgabe: {{ calculateDepositReturnNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateDeposit() > 0">
                                    <div class="col-md-12">
                                        <span>zzgl. Pfand: {{ calculateDeposit() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateFragile() > 0">
                                    <div class="col-md-12">
                                        <span>zzgl. Bruchzuschlag: {{ calculateFragile() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateCargo() > 0">
                                    <div class="col-md-12">
                                        <span>zzgl. Sperrgutzuschlag: {{ calculateCargo() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateCooling() > 0">
                                    <div class="col-md-12">
                                        <span>zzgl. Kühlzuschlag: {{ calculateCooling() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span>zzgl. Versandkosten: {{ calculateShippingNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <small>Alle Werte in Netto außer anders angegeben</small>
                                    </div>
                                </div>

                                <div class="mt-3 row">
                                    <div class="col-md-12">
                                        <hr>
                                        <span>Gesamtsumme (Netto): {{ calculateTotalNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-for="tax in calculateTaxesNetto()" :key="tax.tax">
                                    <div class="col-md-12">
                                        <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.actualTax | toCurrency }}</em></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span><strong>Gesamtsumme (Brutto): {{ calculateTotalBrutto() | toCurrency }}</strong></span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateTotalVoucher() > 0">
                                    <div class="col-md-12">
                                        <span style="color: red">abzgl. Gutschein: {{ calculateTotalVoucher() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateTotalVoucher() > 0">
                                    <div class="col-md-12">
                                        <span><strong>Zahlbetrag (Brutto): {{ calculateTotalBrutto() - calculateTotalVoucher() | toCurrency }}</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Versandkosten</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Wähle</label>
                                    <select class="form-control form-control-sm" v-model="params.dispatch_id">
                                        <option v-for="dispatch in filterDispatches()" :key="dispatch.id" :value="dispatch.id">
                                            {{ dispatch.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Versandkosten</label>
                                    <input type="number" v-model="params.shippingCost"  class="form-control form-control-sm" />
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchShipping" v-model="params.shippingFree">
                                        <label class="custom-control-label" for="customSwitchShipping">Versandkostenfrei?</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="findDispatch() == true">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="store">Filiale</label>
                                    <select class="form-control form-control-sm" v-model="params.store_id" @change="changeStore">
                                        <option value="">-- Wähle Filiale --</option>
                                        <option v-for="store in stores" :key="store.id" :value="store.id">{{ store.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rabatte</h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Rabatt(%)</label>
                                    <input type="number" class="form-control form-control-sm" v-model="order.discount" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Modus</label>
                                    <select class="form-control form-control-sm" v-model="order.discount_mode">
                                        <option value="percent">%</option>
                                        <option value="absolute">€</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        

                        <div class="form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchDiscount" v-model="params.useDiscount">
                                <label class="custom-control-label" for="customSwitchDiscount">Rabatt anwenden?</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Lieferoptionen</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group" v-if="findDispatch() == false">
                            <label>Abstellhinweis</label>
                            <br>
                            <div class="form-group">
                                <input type="radio" v-model="order.delivery_note" value="none" class="form-check-inline form-check">
                                <label  class="control-label form-check-label" >Kein Abstellhinweis</label>
                            </div>
                            <div v-for="note in notes" :key="note.key" class="form-check-inline form-check form-group">
                                <input type="radio" v-model="order.delivery_note"  :value="note.key" class="form-check-input">
                                <label class="control-label form-check-label">{{ note.title }}</label>
                            </div>
                            <div class="form-group" v-if="order.delivery_note == 'andere'">
                                <input v-model="order.delivery_note_additional" type="text" class="form-control form-control-sm" placeholder="...mein Wunschort">   
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Alternativartikel</label>
                            <div class="ml-2 form-check">
                                <input v-model="order.alternative" class="form-check-input" id="alternativartikel" type="checkbox" name="alternative" value="1" />

                                <label class="form-check-label" for="alternativartikel">Ja, der Kunde ist bei Bedarf mit einem Ersatzartikel einverstanden</label>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label for="wishdate">Wunsch-Lieferdatum:</label>           
                            <div class="ml-2">
                                <input v-model="order.wishdate" class="form-control form-control-sm" id="wishdate" type="date" name="wishdate" @change="changeWishdate">
                            </div>
                        </div>

                        <div class="form-group" v-if="findDispatch() == true">
                            <label for="wishtime">Wunsch-Lieferuhrzeit:</label>           
                            <div class="ml-2">
                                <select v-model="order.wishtime" class="form-control form-control-sm">
                                    <option v-for="slot in slots" :key="slot" :value="slot">{{ slot }}</option>
                                </select>
                            </div> 
                        </div>

                        <div class="form-group" v-if="findDispatch() == false">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchFixDelivery" v-model="params.fixDelivery" @change="changeFixDelivery">
                                <label class="custom-control-label" for="customSwitchFixDelivery">Fixer Liefertermin</label>
                            </div>
                        </div>

                        <div v-if="params.avisierung_email == false">
                            <div class="form-group" >
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchAvisierung" v-model="params.avisierung" @change="changeAvisierung">
                                    <label class="custom-control-label" for="customSwitchAvisierung">Telefonische Lieferankündigung</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group" v-if="params.avisierung == true">
                            <label>Telefon</label>
                            <input type="text" class="form-control form-control-sm" v-model="params.phone" />
                        </div>


                        <div v-if="params.avisierung == false">
                            <div class="form-group" >
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchAvisierungEmail" v-model="params.avisierung_email" @change="changeAvisierungEmail">
                                    <label class="custom-control-label" for="customSwitchAvisierungEmail">Lieferankündigung per E-Mail</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group" v-if="params.avisierung_email == true">
                            <label>E-Mail</label>
                            <input type="text" class="form-control form-control-sm" v-model="params.email" />
                        </div>

                        <!-- <div v-if="findDispatchExternal() == true && findDispatch() == false">
                            <div class="form-group">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchPrioExternal" v-model="params.prio_ausserhalb" @change="changePrioAusserhalb">
                                    <label class="custom-control-label" for="customSwitchPrioExternal">PRIO (+ 3,00 €)</label>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Kommentar</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Intern</label>
                            <textarea class="form-control" rows="4" v-model="order.internalcomment"></textarea>
                            <label class="mt-2">Extern</label>
                            <textarea class="form-control" rows="4" v-model="order.externalcomment"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Kundeninformation</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Interner Kundenkommentar:</label><br>
                            <span v-if="params.customer.comment != null" v-html="params.customer.comment"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Positionen</h3>

                        <div class="card-tools" >

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchCool" v-model="params.useCool">
                                    <label class="custom-control-label" for="customSwitchCool">Kühlversand</label>
                                </div>
                            </div>

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchFragile" v-model="params.useFragile">
                                    <label class="custom-control-label" for="customSwitchFragile">Bruchzuschlag</label>
                                </div>
                            </div>

                            <div class="mr-1 form-group" style="display: inline-block;">
                                <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchCargo" v-model="params.useCargo">
                                    <label class="custom-control-label" for="customSwitchCargo">Sperrgut</label>
                                </div>
                            </div>


                            <!-- <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addDepositReturn"><i class="fas fa-plus"></i> Pfandrückgabe</button> -->
                            <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                            <button class="mr-1 btn btn-secondary" @click="addDeposit"><i class="fas fa-plus"></i> Pfandverkauf</button>
                            <button class="btn btn-success" @click="showArticleModal"><i class="fas fa-plus"></i> Hinzufügen</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                                <th style="width:10%;">Artikelnummer</th>
                                <th style="width: 54%;">Name</th>
                                <th style="width: 6%;">Rabattfähig</th>
                                <th style="width: 6%;">Anzahl</th>
                                <th style="width: 10%;" v-if="params.tax==false">Preis (Netto)</th>
                                <th style="width: 10%;" v-if="params.tax==true">Preis</th>
                                <th v-if="params.tax==false">Total (Netto)</th>
                                <th v-if="params.tax==true">Total</th>
                                <th>Steuersatz</th>
                                <th>Aktion</th>
                            </thead>

                            <tbody>
                                <tr v-for="(article, index) in params.articles" :key="article.id">
                                    <td>
                                        <input v-if="article.type == 'article_free'" type="text" v-model="params.articles[index].articleordernumber" class="form-control form-control-sm" />
                                        <span v-else>{{ article.articleordernumber }}</span>
                                    </td>
                                    <td>
                                        <input v-if="article.type == 'article_free' || article.type == 'deposit_sale'" type="text" v-model="params.articles[index].name" class="form-control form-control-sm" />
                                        <span v-else>{{ article.name }}
                                            <br>
                                            <span v-if="article.deposit > 0"><small>Pfandartikel</small></span>
                                        </span>
                                    </td>
                                    <td>
                                        <!-- <div class="mr-1 form-group" style="display: inline-block;"> -->
                                            <div class="custom-control custom-switch" :class="article.type != 'deposit_sale' ? 'custom-switch-off-danger custom-switch-on-success' : ''">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitchDiscountable' + index" v-model="params.articles[index].discountable" :disabled="article.type == 'deposit_sale'">
                                                <label class="custom-control-label" :for="'customSwitchDiscountable' + index"></label>
                                            </div>
                                        <!-- </div> -->
                                    </td>
                                    <td><input type="number" class="form-control form-control-sm" v-model="params.articles[index].quantity" oninput="validity.valid||(value='');" min="0" step="1" /></td>
                                    <td>
                                        <input v-if="params.tax==false" type="number" class="form-control form-control-sm" v-model="params.articles[index].price_netto" min="0" step="0.01" oninput="validity.valid||(value='');" />
                                        <input v-if="params.tax==true" type="number" class="form-control form-control-sm" v-model="params.articles[index].price" min="0" step="0.01" oninput="validity.valid||(value='');" />
                                    </td>
                                    <td>
                                        <template v-if="params.tax==false">{{ article.price_netto * article.quantity  | toCurrency }}</template>
                                        <template v-if="params.tax==true">{{ article.price * article.quantity  | toCurrency }}</template>
                                    </td>
                                    <td>
                                        <select v-if="article.type == 'article_free' || article.type=='deposit_sale'" v-model="params.articles[index].tax_rate" class="form-control form-control-sm" >
                                            <option v-for="tax in taxes" :key="tax.id" :value="tax.tax">{{tax.description}}</option>
                                        </select>
                                        <span v-else>{{ article.tax_rate }}</span>
                                        
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" @click="removeItem(index)"><i class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>              
                    </div>  

                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-7">

                            </div>
                            <div class="text-right col-md-5">
                                <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                                <button class="mr-1 btn btn-secondary" @click="addDeposit"><i class="fas fa-plus"></i> Pfandverkauf</button>
                                <button class="btn btn-success mr-1" @click="showArticleModal"><i class="fas fa-plus"></i> Hinzufügen</button>
                                <button class="btn btn-danger" @click="saveOrder" :class="{disabled: !notEmptyObject(this.params.customer) || this.params.articles.length <= 0 || this.params.payment_id == '' || this.calculateTotalBrutto() <= 0 || (this.params.avisierung == true && this.params.phone == null) || (this.params.avisierung_email == true && this.params.email == null) || (this.params.fixDelivery == true && this.order.wishdate == '')}"><i class="fas fa-save"></i> Angebot speichern</button>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>


        <ArticleModal />

        <b-modal id="addressModal" :title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="createAddress">
            <form ref="valueForm" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Firma</label>
                            <div class="col-sm-9">
                                <select class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}" v-model="form.type">
                                    <option value="address">Adresse</option>
                                    <option value="packstation">Packstation</option>
                                    <option value="postfiliale">Postfiliale</option>
                                </select>
                                <has-error :form="form" field="type"></has-error>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row" v-if="form.type == 'address'">
                            <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Firma</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="1" class="form-control form-control-sm" name="company" id="company" :class="{'is-invalid': form.errors.has('company')}" v-model="form.company">
                                <has-error :form="form" field="company"></has-error>
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <label for="first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="3" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                                <has-error :form="form" field="first_name"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="form.type == 'address'">
                            <label for="street" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="street" id="street" :class="{'is-invalid': form.errors.has('street')}" v-model="form.street">
                                <has-error :form="form" field="street"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="form.type != 'address'">
                            <label for="post_customer_number" class="control-label col-sm-2 col-form-label col-form-label-sm">DHL Kd.-Nr.*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="post_customer_number" id="post_customer_number" :class="{'is-invalid': form.errors.has('post_customer_number')}" v-model="form.post_customer_number">
                                <has-error :form="form" field="post_customer_number"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="zipcode" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="7" autocomplete="postal-code" class="form-control form-control-sm" name="zipcode" id="zipcode" :class="{'is-invalid': form.errors.has('zipcode')}" v-model="form.zipcode">
                                <has-error :form="form" field="zipcode"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="phone" class="control-label col-sm-2 col-form-label col-form-label-sm">Telefon</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="9" class="form-control form-control-sm" name="phone" id="phone" :class="{'is-invalid': form.errors.has('phone')}" v-model="form.phone">
                                <has-error :form="form" field="phone"></has-error>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="form-group row" v-if="form.type == 'address'">
                            <label for="department" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Abteilung</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="2" name="department" id="department" class="form-control form-control-sm" v-model="form.department" :class="{'is-invalid': form.errors.has('department')}">
                                <has-error :form="form" field="department"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="last_name" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Nachname*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="4" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                                <has-error :form="form" field="last_name"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="form.type == 'address'">
                            <label for="house_number" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Hausnummer*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="6" name="house_number" id="house_number" class="form-control form-control-sm" v-model="form.house_number" :class="{'is-invalid': form.errors.has('house_number')}">
                                <has-error :form="form" field="house_number"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="form.type != 'address'">
                            <label for="post_number" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Station ID*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="post_number" id="post_number" :class="{'is-invalid': form.errors.has('post_number')}" v-model="form.post_number">
                                <has-error :form="form" field="post_number"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="city" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Stadt*</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="8" autocomplete="address-line2" name="city" id="city" class="form-control form-control-sm" v-model="form.city" :class="{'is-invalid': form.errors.has('city')}">
                                <has-error :form="form" field="city"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="ustid" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Ust.-ID</label>
                            <div class="col-sm-9">
                                <input type="text" tabindex="10" name="ustid" id="ustid" class="form-control form-control-sm" v-model="form.ustid" :class="{'is-invalid': form.errors.has('ustid')}">
                                <has-error :form="form" field="ustid"></has-error>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal id="customerModal" title="Kunde wählen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Versanddaten</h3>
                <div class="card-tools">
                   
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getCustomers" v-model="search" />
                        <button type="submit" class="btn btn-default" @click.prevent="getCustomers">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                                    
                                
                </div>
              </div>
              <div class="card-body">
                <table class="table table-striped">
                                    <thead>
                                        <tr data-widget="expandable-table" aria-expanded="false">
                                            <th>
                                                Kundennummer
                                            </th>
                                            <th>
                                                E-Mail
                                            </th>
                                            <th>
                                                Firma
                                            </th>
                                            <th>
                                                Vorname
                                            </th>
                                            <th>
                                                Nachname
                                            </th>
                                            <th>
                                                PLZ
                                            </th>
                                            <th>
                                                Stadt
                                            </th>
                                            <th>Aktionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="customer in customers.data" :key="customer.id">
                                            <td>{{ customer.customernumber}}</td>
                                            <td>{{ customer.email}}</td>
                                            <td>{{ customer.billing_address.company }}</td>
                                            <td>{{ customer.first_name }}</td>
                                            <td>{{ customer.last_name }}</td>
                                            <td>{{ customer.billing_address.zipcode }}</td>
                                            <td>{{ customer.billing_address.city }}</td>
                                            <td>
                                                <b-button size="xs" @click="setCustomer(customer)" variant="primary"><i class="fas fa-user"></i> Auswählen</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
              </div>
              <div class="card-footer">
                        <pagination class="float-left" :data="customers" @pagination-change-page="getCustomers" :limit="3"></pagination>
                        <p v-if="notEmptyObject(customers)" class="float-right mr-2">Anzeige Eintrag {{ customers.meta.from }} - {{ customers.meta.to }} von {{ customers.meta.total }}</p>
                    </div>
            </div>
          </div>
        </div>

        
    </b-modal>
    </b-container>
</template>

<script>
import ArticleModal from './components/AddModal';
import moment from 'moment'

export default {
    components: {
      ArticleModal,
  },

    data() {
        return {
            ShippingTaxMode: process.env.VUE_APP_SHIPPING_TAX,
            notes: [
                {
                    key: 'garage', 
                    title: 'Garage', 
                }, 
                {
                    key: 'terrasse', 
                    title: 'Terrasse', 
                }, 
                {
                    key: 'kellertreppe', 
                    title: 'Kellertreppe', 
                }, 
                {
                    key: 'andere', 
                    title: 'Andere', 
                }, 
            ],
            slots: [],
            stores: [], 
            companies: [],
            customers: {},
            payments: [],
            dispatches: [],
            articles: [],
            taxes: [],
            selectedRow: {},
            params: {
                tax: true,
                company_id: '',
                customer: '',
                useBillingAddress: "true",
                billing_id: '',
                shipping_id: '',
                shipping_address: {},
                payment_id: '',
                dispatch_id: '',
                articles: [],
                useCool: true,
                useCargo: true,
                useFragile: true,
                useDiscount: true,
                shippingFree: false,
                shippingCost: 7.90,
                store_id: '', 
                service: false,
                fixDelivery: false, 
                avisierung: false,
                phone: null,
                avisierung_email: false,
                email: null,
                prio_ausserhalb: false, 
            },

            order: {
                total_brutto: 0,
                total_netto: 0,
                total_pledge: 0,
                total_cool: 0,
                total_fragile: 0,
                total_cargo: 0,
                total_shipping: 0,
                total_shipping_net: 0,
                total_shipping_tax: '7.00',
                total_discount: 0,
                zwischensumme: 0,
                deposit: [],
                deposit_additional: [],
                tax: [],
                heavy: [],
                fragile: [], 
                customercomment: '',
                internalcomment: '',
                externalcomment: '',
                discount: 0,
                discount_mode: 'percent',
                alternative: false, 
                wishdate: '', 
                delivery_note: 'none', 
                delivery_note_additional: '', 
                expires_at: moment().add(14, 'days').format('YYYY-MM-DD'),
                vouchers: [],
            },
            form: new window.Form({
                id: "",
                customer_id: "",
                first_name: "",
                last_name: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                company: "",
                department: "",
                phone: "",
                ustid: "",
                type: "address",
                post_number: "",
                post_customer_number: "",
            }),
            modalTitle: '',

            finalOrder: {},
            
            search: '',
        }
    },

    watch: {
        'params.company_id': function() {
            this.params.customer_id =  '';
        },

        'params.dispatch_id': function() {
            let costs = this.filterDispatches().find(element => element.id == this.params.dispatch_id);
             if(costs != undefined && costs != null)
            {
                this.params.shippingCost = costs.amount;
            }
            else{
                this.params.shippingCost = 7.90
            }

            // const dispatch = this.dispatches.find(element => element.id == this.params.dispatch_id);
            // this.params.prio_ausserhalb = dispatch.prio_external == 0 ? false : true;
        },

        'params.useBillingAddress': function() {
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }
        },

        'params.billing_id': function() {
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                const shipping = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
                this.params.shipping_address = shipping;
                this.params.shipping_id = shipping.id
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                const shipping = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
                this.params.shipping_address = shipping;
                this.params.shipping_id = shipping.id
            }
        },

        'params.shipping_id': function() {
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }
        },

        'params.customer': function() {
            this.params.shipping_id = this.params.customer.default_shipping_address_id;
            this.params.billing_id = this.params.customer.default_billing_address_id;

            if(this.params.customer.group.tax == 1){
                this.params.tax = true;
            }
            else{
                this.params.tax = false;
            }

            //Prüfe ob Kunde einen Kundenrabatt oder Gruppenrabatt hat
            if(this.params.customer.discount > 0)
            {
                //Es gibt auf jeden Fall einen Kunden Rabatt
               
                this.order.discount = this.params.customer.discount;
                this.order.discount_mode = 'percent';
            }
            else{
                //Es gibt keinen Kundenrabatt. Prüfe ob es einen Kundengruppenrabatt gibt
                if(this.params.customer.group.discount > 0)
                {
                    //Kundengruppenrabatt
                    this.order.discount = this.params.customer.group.discount;
                    this.order.discount_mode = 'percent';
                }
                else{
                    this.order.discount = 0;
                    this.order.discount_mode = 'percent';
                }
            }

            //var address = null;
            if(this.params.useBillingAddress == true)
            {
                //Keine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.billing_id);
            }

            else
            {
                //Es gibt eine abweichende Lieferanschrift
                this.params.shipping_address = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }       
        },

        'params.articles': {
            handler() {
            },
            deep: true,
        },
        'params.tax': {
            handler(newValue){
                if(newValue == true){
                    //Wir benutzen jetzt Brutto
                    this.changeToBrutto();
                }

                if(newValue == false){
                    //Wir benutzen jetzt Netto
                    this.changeToNetto();
                }
            }
        }
    },

    methods: {
        filterDispatches() {
            var availableDispatches = [];
            this.dispatches.forEach(function(item) {
                if(item.company_id == this.params.company_id){
                    availableDispatches.push(item);
                }
            },this);
            //Prüfe nun auf Kühlversand
            if(this.needCool() == true){
                //Filter alle Versandarten die Kühle habe
                availableDispatches = availableDispatches.filter(el => {
                    return el.cool === 1
                })
            }
            else{
                //Cart braucht keine Kühlung. Also müssen die Versandarten raus, die Kühlung anbieten
                availableDispatches = availableDispatches.filter(el => {
                    return el.cool === 0
                })
            }
            var shipping = undefined;
            if(this.params.customer != ''){
                shipping = this.params.customer.addresses.find(i => i.id == this.params.shipping_id);
            }
            
            //Prüfe ob Versandart durch die Region erlaubt ist
            if(shipping != undefined){
                availableDispatches = availableDispatches.filter(el => {
                    if(el.include_zipcode != null){
                        var allowed = false;
                        var zipcodes = el.include_zipcode.split(',');
                        const BreakError = {};
                        try{
                            zipcodes.forEach(function(item) {
                                var pos = item.indexOf('*');
                                if(pos != -1){
                                    var string = item.substring(0, pos);
                                    var pattern = new RegExp("^" + string + '[0-9]{' + (5-pos) + '}$');
                                    var regex = shipping.zipcode.match(pattern)
                                    if(regex != null){
                                        allowed = true;
                                        throw BreakError;
                                    }
                                    else{
                                        allowed = false;
                                    }
                                }
                                else{
                                    if(item == shipping.zipcode){
                                        allowed = true;
                                        throw BreakError;
                                    }
                                    else{
                                        allowed = false;
                                    }
                                }
                            });
                        } catch (err) {
                            if(err !== BreakError) throw err;
                        }
                        if(allowed == false)
                        {
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                    return true;
                });
            }


            //Prüfe ob Versandart durch die Region verboten ist
            if(shipping != undefined){
                availableDispatches = availableDispatches.filter(el => {
                    if(el.exclude_zipcode != null){
                        var allowed = false;
                        var zipcodes = el.exclude_zipcode.split(',');
                        const BreakError = {};
                        try{
                            zipcodes.forEach(function(item) {
                                var pos = item.indexOf('*');
                                if(pos != -1){
                                    var string = item.substring(0, pos);
                                    var pattern = new RegExp("^" + string + '[0-9]{' + (5-pos) + '}$');
                                    var regex = shipping.zipcode.match(pattern)
                                    if(regex == null){
                                        allowed = true;
                                        
                                    }
                                    else{
                                        allowed = false;
                                        throw BreakError;
                                    }
                                }
                                else{
                                    if(item != shipping.zipcode){
                                        allowed = true;
                                        
                                    }
                                    else{
                                        allowed = false;
                                        throw BreakError;
                                    }
                                }
                            });
                        } catch (err) {
                            if(err !== BreakError) throw err;
                        }
                        if(allowed == false)
                        {
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                    return true;
                });
            }

            if(availableDispatches.length == 0){
                this.params.dispatch_id = '';
            }
            else{
                var currentDispatch = availableDispatches.find(item => item.id == this.params.dispatch_id);
                if(currentDispatch == undefined){
                    //Versandart musste geändert werden
                    //Man könnte jetzt einen Dialog puschen
                    this.params.dispatch_id = availableDispatches[0].id;
                    // this.params.schippingCost = availableDispatches[0].amount;
                }
            }
            return availableDispatches;
        },
        changeToBrutto(){
            this.params.articles.forEach(function(item) {
                
                if(item.type == 'article' || item.type == 'bundle'){
                    if(item.price_netto != item.price_netto_orig){
                        item.price = parseFloat(item.price_netto / 100 * (100 + parseFloat(item.tax_rate))).toFixed(2);
                    }
                    else{
                        item.price = item.price_orig;
                    }
                    
                }

                if(item.type == 'article_free'){
                    item.price = parseFloat(item.price_netto / 100 * (100 + parseFloat(item.tax_rate))).toFixed(2);
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb' || item.type == 'deposit_sale' || item.type == 'deposit_return'){
                    item.price = item.price_netto;
                }
            });
        },

        changeToNetto(){
            this.params.articles.forEach(function(item) {
                
                if(item.type == 'article' || item.type == 'bundle'){
                    if(item.price != item.price_orig){
                        item.price_netto = parseFloat(item.price / (100 + parseFloat(item.tax_rate)) * 100).toFixed(2);
                    }
                    else{
                        item.price_netto = item.price_netto_orig;
                    }
                }

                if(item.type == 'article_free'){
                    item.price_netto = parseFloat(item.price / (100 + parseFloat(item.tax_rate)) * 100).toFixed(2);
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb' || item.type == 'deposit_sale' || item.type == 'deposit_return'){
                    item.price_netto = item.price;
                }
            });
        },

        getCustomer(id){
            this.axios
                .get("/customers/" + id)
                .then((response) => {
                    this.params.customer = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        createAddress(bvModalEvt)
        {
            bvModalEvt.preventDefault()
            this.form.customer_id = this.params.customer.id;
            this.form
                .post("/addresses")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Addresse erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("addressModal");
                    this.getCustomer(this.form.customer_id);                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        openAddressModal()
        {
            this.modalTitle = "Neue Adresse anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("addressModal");
        },

        changeAvisierung(){
            if(this.params.avisierung == true){
                //Füge zu Items hinzu
                this.params.articles.push({
                    'articleordernumber': 'LA',
                    'name': 'Telefonische Lieferankündigung',
                    'quantity': 1,
                    'price': 2.9,
                    'tax_rate': '7.00',
                    'article_id': 0,
                    'article_detail_id': 0,
                    'type': 'article_tel',
                    'deposit': 0,
                    'deposit_type': 'Mehrweg-Pfand',
                    'cool': 0,
                    'fragile': 0,
                    'heavy': 0,
                    'discountable': false,
                });
            }

            if(this.params.avisierung == false){
                //Lösche von Items
                var item = this.params.articles.findIndex(item => item.type == 'article_tel');

                if(item != -1){
                    this.$delete(this.params.articles, item);
                }
            }
        },

        changeAvisierungEmail(){
            if(this.params.avisierung_email == true){
                //Füge zu Items hinzu
                this.params.articles.push({
                    'articleordernumber': 'LA',
                    'name': 'Lieferankündigung per E-Mail',
                    'quantity': 1,
                    'price': 2.5,
                    'tax_rate': '7.00',
                    'article_id': 0,
                    'article_detail_id': 0,
                    'type': 'article_email',
                    'deposit': 0,
                    'deposit_type': 'Mehrweg-Pfand',
                    'cool': 0,
                    'fragile': 0,
                    'heavy': 0,
                    'discountable': false,
                });
            }

            if(this.params.avisierung_email == false){
                //Lösche von Items
                var item = this.params.articles.findIndex(item => item.type == 'article_email');

                if(item != -1){
                    this.$delete(this.params.articles, item);
                }
            }
        },

        changePrioAusserhalb(){
            if(this.params.prio_ausserhalb == true){
                //Füge zu Items hinzu
                this.params.articles.push({
                    'articleordernumber': 'PRIO',
                    'name': 'Prio-Versand',
                    'quantity': 1,
                    'price': 3,
                    'tax_rate': '7.00',
                    'article_id': 0,
                    'article_detail_id': 0,
                    'type': 'prio_ausserhalb',
                    'deposit': 0,
                    'deposit_type': 'Mehrweg-Pfand',
                    'cool': 0,
                    'fragile': 0,
                    'heavy': 0,
                    'discountable': false,
                });
            }

            if(this.params.prio_ausserhalb == false){
                //Lösche von Items
                var item = this.params.articles.findIndex(item => item.type == 'prio_ausserhalb');

                if(item != -1){
                    this.$delete(this.params.articles, item);
                }
            }
        },

        changeWishdate(){

            var store = this.stores.find(item => item.id == this.params.store_id); 

            if(store == undefined) 
            {
                return false;  
            }



            var businesshours = store.business_hours; 

        
            var timestamp = moment(this.order.wishdate);
            var day = timestamp.day(); 
            
            var mybusinesshour = businesshours[day - 1]; 

            var start = moment('2021-01-01 ' + mybusinesshour.open_time); 
            var end = moment('2021-01-01 ' + mybusinesshour.close_time);
            var differenz = (end - start) / 3600 / 1000;
            
            

            var duration = Math.round(differenz / 3);  
            
            var string1 = moment(start).format('HH:mm') + '-' + moment(start).add(duration, 'h').format('HH:mm'); 
            // console.log(string1);
            var string3 = moment(end).subtract(duration, 'h').format('HH:mm') + '-' + moment(end).format('HH:mm');
            // console.log(string3);
            var string2 = moment(start).add(duration - 1, 'h').format('HH:mm') + '-' + moment(end).subtract(duration-1, 'h').format('HH:mm'); 
            // console.log(string2);

            this.slots = [];
            this.slots.push(string1);
            this.slots.push(string2);
            this.slots.push(string3);

            this.order.wishtime = string1;
        },

        changeStore(){
            
            // Prüfe ob es schon ein Wunsch Lieferdatum gibt
            if(this.order.wishdate == null || this.order.wishdate == "")
            {
                // Es ist noch kein Wunschdatum gesetzt. Setzte 3 Tage im Vorraus
                // var dt = new Date();
                // dt.setDate(dt.getDate() + 3);
                this.order.wishdate = moment().add(3, 'days').format('YYYY-MM-DD');
            }

            this.changeWishdate();
        },

        changeFixDelivery(){
            if(this.params.fixDelivery == true){
                //Füge zu Items hinzu
                this.params.articles.push({
                    'articleordernumber': 'VWLT',
                    'name': 'Verbindlicher Wunschliefertag',
                    'quantity': 1,
                    'price': 3,
                    'tax_rate': '7.00',
                    'article_id': 0,
                    'article_detail_id': 0,
                    'type': 'article_flt',
                    'deposit': 0,
                    'deposit_type': 'Mehrweg-Pfand',
                    'cool': 0,
                    'fragile': 0,
                    'heavy': 0,
                    'discountable': false,
                });
            }

            if(this.params.fixDelivery == false){
                //Lösche von Items
                var item = this.params.articles.findIndex(item => item.type == 'article_flt');

                if(item != -1){
                    this.$delete(this.params.articles, item);
                }
                this.params.phone = null;
            }
        },

        findDispatchExternal()
        {
            var dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id); 

            if(dispatch == undefined) 
            {
                return false;  
            }
            else if(dispatch.extended_service == 0)
            {
                return true; 
            }
            else
            {
                return false; 
            }
        }, 

        findDispatch()
        {
            var dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id); 

            if(dispatch == undefined) 
            {
                return false;  
            }
            else if(dispatch.name == 'Selbstabholung')
            {
                return true; 
            }
            else
            {
                return false; 
            }
        }, 

        setCustomer(customer){
            this.params.customer = customer;
            this.$bvModal.hide('customerModal');
        },

        selectCustomer()
        {
            this.getCustomers();
            this.$bvModal.show('customerModal');
        },


        saveOrder()
        {
            this.finalOrder.tax = this.params.tax;
            this.finalOrder.customer_id = this.params.customer.id;
            this.finalOrder.company_id = this.params.company_id;
            this.finalOrder.dispatch_id = this.params.dispatch_id;
            this.finalOrder.payment_id = this.params.payment_id;
            this.finalOrder.internalcomment = this.order.internalcomment;
            this.finalOrder.externalcomment = this.order.externalcomment;
            this.finalOrder.invoice_amount = this.calculateTotalBrutto();
            this.finalOrder.invoice_amount_net = this.calculateTotalNetto();
            this.finalOrder.invoice_shipping_tax_rate = this.getTaxForShipping();
            this.finalOrder.billing_address_id = this.params.billing_id;
            this.finalOrder.shipping_address_id = this.params.shipping_id;
            this.finalOrder.shippingFree = this.params.shippingFree;
            this.finalOrder.shippingCost = this.getShippingWithTax();
            // this.finalOrder.shippingCostNetto = this.getShippingWithoutTax();
            this.finalOrder.discount = this.order.discount;
            this.finalOrder.discount_mode = this.order.discount_mode;
            this.finalOrder.useDiscount = this.params.useDiscount;
            this.finalOrder.useFragile = this.params.useFragile;
            this.finalOrder.useCargo = this.params.useCargo;
            this.finalOrder.useCool = this.params.useCool;
            this.finalOrder.alternative = this.order.alternative;
            this.finalOrder.wishdate = this.order.wishdate;

            //Wenn Selbstabholung dann setzte Store ID und Wishtime
            const ship = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            if(ship != undefined){
                if(ship.pickup == 1){
                    this.finalOrder.store_id = this.params.store_id;
                    this.finalOrder.wishtime = this.order.wishtime
                }
            }
            
            this.finalOrder.delivery_note = this.order.delivery_note;
            this.finalOrder.delivery_note_additional = this.order.delivery_note_additional;
            this.finalOrder.expires_at = this.order.expires_at;
            this.finalOrder.items = [];

            if(this.params.avisierung == 1){
                this.finalOrder.phone = this.params.phone;
                this.finalOrder.avisierung = this.params.avisierung;
            }

            if(this.params.avisierung_email == 1){
                this.finalOrder.email = this.params.email;
                this.finalOrder.avisierung_email = this.params.avisierung_email;
            }

            this.finalOrder.fixWishdate = 0;

            if(this.params.fixDelivery == 1){
                this.finalOrder.fixWishdate = 1;
            }

            this.finalOrder.prioExternal = 0;

            if(this.params.prio_ausserhalb == 1){
                this.finalOrder.prioExternal = 1;
            }

            if(parseFloat(this.calculateDiscount()) > 0){
                this.finalOrder.total_discount = this.calculateDiscount();
            }
            else
            {
                this.finalOrder.total_discount = null;
            }

            var data;
            this.params.articles.forEach(function(item) {
                //Füge jeden Artikel hinzu :-)
                data = {};
                data.articleordernumber = item.articleordernumber;
                data.quantity = item.quantity;

                data.tax_rate = item.tax_rate;
                data.article_id = item.article_id;
                data.article_detail_id = item.article_detail_id;
                data.discountable = item.discountable;
                if(this.params.tax == true){
                    data.price = Number(parseFloat(item.price).toFixed(2));
                }
                else{
                    data.price = Number(parseFloat(item.price_netto / 100 * (parseFloat(item.tax_rate) + 100)).toFixed(2));
                }

                data.name = item.name;
                data.cool = item.cool;
                data.deposit = item.deposit;
                data.deposit_type = item.deposit_type;
                data.fragile = item.fragile;
                data.heavy = item.heavy;
                data.type = item.type;
            
                this.finalOrder.items.push(data);
            },this);
                
            

            //Schreibe die Daten in die Datenbank
            this.$swal({
                    title: "Möchtest du das Angebot wirklich speichern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, speichern!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/offers", this.finalOrder)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Angebot erstellt",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.$router.push({name: 'offers.index'});
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });

        },

        removeItem(index, type)
        {
            this.$delete(this.params.articles, index);

            if(type == 'article_flt'){
                this.params.fixDelivery = false;
            }

            if(type == 'article_tel'){
                this.params.avisierung = false;
                this.params.phone = null;
            }

            if(type == 'article_email'){
                this.params.avisierung_email = false;
                this.params.email = null;
            }

            if(type == 'prio_ausserhalb'){
                this.params.prio_ausserhalb = false;
            }
        },

        hasCool(){
            var result = false;
            this.params.articles.forEach(function(item) {
                if(item.cool == 1)
                {
                    result = true;
                }
            },result);
            return result;
        },

        addFreeArticle(){
            this.params.articles.push({
                'articleordernumber': 'FA',
                'name': 'Freier Artikel',
                'quantity': 1,
                'price': 1,
                'price_netto': 1,
                'tax_rate': '7.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'article_free',
                'deposit': 0,
                'deposit_type': '-none-',
                'cool': 0,
                'fragile': 0,
                'heavy': 0,
                'discountable': true,
            });
                    

            this.params.articles.sort(function(a, b) {
                const name1 = a.articleordernumber.toUpperCase();
                const name2 = b.articleordernumber.toUpperCase();

                let comparison = 0;

                if (name1 > name2) {
                    comparison = 1;
                } else if (name1 < name2) {
                    comparison = -1;
                }
                return comparison;
            })
        },

        addDeposit(){
            this.params.articles.push({
                'articleordernumber': 'Pfandverkauf',
                'name': 'Pfandverkauf',
                'quantity': 1,
                'price': 1,
                'price_netto': 1,
                'tax_rate': '19.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'deposit_sale',
                'deposit': 0,
                'deposit_type': 'Mehrweg-Pfand',
                'cool': 0,
                'fragile': 0,
                'heavy': 0,
                'discountable': false,
            });
        },

        addDepositReturn(){

        },

        calculateSubTotalNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                

                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }

                if(item.type == 'deposit_sale')
                {
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateSubTotalBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                

                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }

                if(item.type == 'deposit_sale')
                {
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnNettoByTax(tax){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return' && item.tax_rate == tax){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnBruttoByTax(tax){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return' && item.tax_rate == tax){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDeposit(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    if(item.deposit > 0)
                    sum += parseFloat(item.deposit).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateFragile(){
            var sum = 0;
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);
            if(surcharge == 1 && this.params.useFragile == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.fragile == 1)
                        sum += parseFloat(company.fragileAmount) * item.quantity;
                    }
                });
            }
            return sum;
        },

        getFragileByTax(){
            var fragile = [];
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            if(surcharge == 1 && this.params.useFragile == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.fragile == 1){
                            var tax = fragile.find(i => i.type == item.tax_rate);
                            if(tax == undefined)
                            {
                                //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: item.tax_rate,
                                    amount: parseInt(item.quantity),
                                };

                                fragile.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                fragile.amount = parseInt(fragile.amount) + parseInt(item.quantity);
                            }
                        }
                    }
                });
            }
            return fragile;
        },

        getCargoByTax(){
            var cargo = [];
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            if(surcharge == 1 && this.params.useCargo == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.heavy == 1){
                            var tax = cargo.find(i => i.type == item.tax_rate);
                            if(tax == undefined)
                            {
                                //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: item.tax_rate,
                                    amount: parseInt(item.quantity),
                                };

                                cargo.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                cargo.amount = parseInt(cargo.amount) + parseInt(item.quantity);
                            }
                        }
                    }
                });
            }
            return cargo;
        },

        getDepositByTax(){
            var deposits = [];
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            if(surcharge == 1 && this.params.useCargo == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.deposit > 0){
                            //Artikel hat Pfand
                            var string = item.deposit_type + '_' + item.tax_rate;

                            var deposit = deposits.find(i => i.type == string);
                            if(deposit == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: string,
                                    amount: item.deposit * item.quantity,
                                    tax: item.tax_rate,
                                };

                                deposits.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                deposit.amount += (item.deposit * item.quantity);
                            }
                        }
                    }
                });
            }
            return deposits;
        },
        
        calculateCargo(){
            var sum = 0;
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);
            if(surcharge == 1 && this.params.useCargo == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.heavy == 1)
                        sum += parseFloat(company.cargoAmount) * item.quantity;
                    }
                });
            }
            return sum;
        },

        needCool()
        {
            var cool = false;

            this.params.articles.forEach(function(item) {
                if(item.cool == 1){
                    cool = true;
                }
            });

            return cool;
        },


        calculateCooling()
        {
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);

            // Prüfe ob die aktuelle Versandart überhaupt Zuschläge erlaubt.
            if(surcharge == 0 || this.params.useCool == false)
            {
                // Es sind keine Zuschläge erlaubt. Kühlversand ist somit 0;
                return 0;
            }
            if(surcharge == 1 && this.params.useCool == true) {
                // Zuschläge sind generell erlaubt. Prüfe nun ob es überhaupt um einen Kühlversand geht.
                if(dispatch.cool == 1)
                {
                    //Es handelt sich um  Kühlversand. Kosten werden ausgegeben

                    if(this.needCool() == true)
                    {
                        return Number(parseFloat(company.coolingAmount).toFixed(2));
                    }
                    else
                    {
                        return 0;
                    }

                }

                else
                {
                    //Es ist kein Kühlversand, Somit ist Kühlversand = 0
                    return 0;
                }

            }
        },

        getShippingWithoutTax(){
            if(this.params.tax == true){
                //Es ist Privatkunde
                return Number(parseFloat(parseFloat(this.calculateShippingBrutto()) / (100 + parseFloat(this.getTaxForShipping())) * 100).toFixed(2));
            }
            else{
                //Firmenkunde
                return Number(parseFloat(this.calculateShippingNetto().toFixed(2)));
            }
        },

        getShippingWithTax(){
            if(this.params.tax == true){
                //Es ist Privatkunde
                return Number(parseFloat(this.calculateShippingBrutto().toFixed(2)));
            }
            else{
                //Firmenkunde
                return Number(parseFloat(parseFloat(this.calculateShippingNetto()) / 100 * (parseFloat(this.getTaxForShipping()) + 100)).toFixed(2));
            }
        },

        getTaxForShipping(){
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum -= Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(tax == undefined)
                {
                    //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);

            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};
                if(item.amount != 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalBrutto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnBrutto()) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalBrutto() - this.calculateDiscountBrutto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnBrutto()) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            var shippingTax = '19.00';
            if(this.ShippingTaxMode == '19')
            {
                shippingTax = '19.00';
            }

            else if(this.ShippingTaxMode == '7')
            {
                shippingTax = '7.00';
            }

            else if(this.ShippingTaxMode == 'calculate')
            {
                shippingTax = '19.00';
                if(taxes.length > 0){
                    const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
                    shippingTax = obj.tax;
                }
            }

            return Number(parseFloat(shippingTax).toFixed(2));
        },

        calculateShippingNetto(){
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            if(this.calculateSubTotalNetto() < dispatch.shippingfree)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    return parseFloat(this.params.shippingCost);
                }
                else{
                    return parseFloat(0);
                }
            }
            else{
                return parseFloat(0);
            }
        },

        calculateShippingBrutto(){
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            if(this.calculateSubTotalBrutto() < dispatch.shippingfree)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    return parseFloat(this.params.shippingCost);
                }
                else{
                    return parseFloat(0);
                }
            }
            else{
                return parseFloat(0);
            }
        },

        calculateDiscountNetto(){
            var sum = 0;
            if(this.params.useDiscount == true && this.order.discount > 0){
                if(this.order.discount_mode == 'absolute'){
                    const discountable = this.getDiscountableTotalNetto();
                    
                    if(parseFloat(discountable) <= parseFloat(this.order.discount)){
                        sum = parseFloat(discountable).toFixed(2);
                    }
                    else{
                        sum = parseFloat(this.order.discount).toFixed(2);
                    }
                    
                }
                else{
                    sum = parseFloat(this.getDiscountableTotalNetto() / 100 * this.order.discount).toFixed(2);
                }
            }
            return parseFloat(sum);
        },

        calculateDiscountBrutto(){
            var sum = 0;
            if(this.params.useDiscount == true && this.order.discount > 0){
                if(this.order.discount_mode == 'absolute'){
                    const discountable = this.getDiscountableTotalNetto();
                    
                    if(parseFloat(discountable) <= parseFloat(this.order.discount)){
                        sum = parseFloat(discountable).toFixed(2);
                    }
                    else{
                        sum = parseFloat(this.order.discount).toFixed(2);
                    }
                }
                else{
                    sum = parseFloat(this.getDiscountableTotalBrutto() / 100 * this.order.discount).toFixed(2);
                }
            }
            return parseFloat(sum);
        },

        calculateDiscount(){
            if(this.params.tax == true){
                return this.calculateDiscountBrutto();
            }
            else{
                return this.calculateDiscountNetto();
            }
        },

        getDiscountableTotalNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.discountable == true){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return parseFloat(sum).toFixed(2);
        },

        getDiscountableTotalBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.discountable == true){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return parseFloat(sum).toFixed(2);
        },

        calculateTotalNetto(){
            var sum = 0;

            if(this.params.tax == false){
                sum += this.calculateSubTotalNetto();
                sum -= this.calculateDiscountNetto();
                sum += this.calculateDeposit();
                sum += this.calculateCargo();
                sum += this.calculateFragile();
                sum += this.calculateShippingNetto();
                sum += this.calculateCooling();
                sum -= this.calculateDepositReturnNetto();
            }

            if(this.params.tax == true){
                //Privatkunde
                sum = this.calculateTotalBrutto();
                this.calculateTaxesBrutto().forEach(item => {
                    sum -= item.actualTax;
                });
            }

            return parseFloat(sum);

        },

        calculateTotalBrutto(){
            var sum = 0; 

            if(this.params.tax == false){
                //netto
                sum = this.calculateTotalNetto();
                this.calculateTaxesNetto().forEach(item => {
                    sum += item.actualTax;
                });
            }

            if(this.params.tax == true){
                //netto
                sum += this.calculateSubTotalBrutto();
                sum -= this.calculateDiscountBrutto();
                sum += this.calculateDeposit();
                sum += this.calculateCargo();
                sum += this.calculateFragile();
                sum += this.calculateShippingBrutto();
                sum += this.calculateCooling();
                sum -= this.calculateDepositReturnBrutto();
            }

            return Number(parseFloat(sum).toFixed(2));
        },

        calculateTaxesNetto(){
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum -= Number(parseFloat(item.price_netto).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price_netto).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(tax == undefined)
                {
                    //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);
            // console.log(groupedByTax);
            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};

                if(item.amount != 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalNetto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnNetto()) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalNetto() - this.calculateDiscountNetto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnNetto()) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            var shippingTax = '19.00';
            if(this.ShippingTaxMode == '19')
            {
                shippingTax = '19.00';
            }

            else if(this.ShippingTaxMode == '7')
            {
                shippingTax = '7.00';
            }

            else if(this.ShippingTaxMode == 'calculate')
            {
                shippingTax = '19.00';
                if(taxes.length > 0){
                    const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
                    shippingTax = obj.tax;
                }
            }

            //Addiere jetzt noch den Kühlzuschlag
            // Fest 7% Auf Kühlzuschlag
            if(this.calculateCooling() > 0){
                var tax = taxes.findIndex(item => item.tax == '7.00');
                if(tax == -1){
                    //Versand existiert nicht. Muss erzeugt werden
                    const taxNew = {
                        'tax':  '7.00',
                        'regularAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
                        'regularTax': Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
                        'actualTax': Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2)),
                    };
                    taxes.push(taxNew)
                }
                else{
                    taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
                    taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2))).toFixed(2));
                    taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
                    taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2))).toFixed(2));
                }
            }


            // Jetzt addiere noch den Versand
            tax = taxes.findIndex(item => item.tax == shippingTax);
            if(tax == -1){
                //Versand existiert nicht. Muss erzeugt werden
                const taxNew = {
                    'tax':  shippingTax,
                    'regularAmount': Number(parseFloat(this.calculateShippingNetto()).toFixed(2)),
                    'regularTax': Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2)),
                    'percentOfSubtotal': 0,
                    'actualAmount': Number(parseFloat(this.calculateShippingNetto()).toFixed(2)),
                    'actualTax': Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2)),
                };
                taxes.push(taxNew)
            }
            else{
                taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateShippingNetto()).toFixed(2))).toFixed(2));
                taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
                taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateShippingNetto()).toFixed(2))).toFixed(2));
                taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            }
            // console.log(taxes);
            return taxes;

            // console.log(taxes);
        },


        calculateTaxesBrutto(){
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            if(dispatch != undefined){
                surcharge = dispatch.surcharge;
            }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum -= Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(tax == undefined)
                {
                    //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);

            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};
                if(item.amount != 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalBrutto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnBrutto()) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalBrutto() - this.calculateDiscountBrutto() + this.calculateDeposit() + this.calculateCargo() + this.calculateFragile() - this.calculateDepositReturnBrutto()) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            var shippingTax = '19.00';
            if(this.ShippingTaxMode == '19')
            {
                shippingTax = '19.00';
            }

            else if(this.ShippingTaxMode == '7')
            {
                shippingTax = '7.00';
            }

            else if(this.ShippingTaxMode == 'calculate')
            {
                shippingTax = '19.00';
                if(taxes.length > 0){
                    const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
                    shippingTax = obj.tax;
                }
            }

            if(this.calculateCooling() > 0){
                //Addiere jetzt noch den Kühlzuschlag
                // Fest 7% Auf Kühlzuschlag
                var tax = taxes.findIndex(item => item.tax == '7.00');
                if(tax == -1){
                    //Versand existiert nicht. Muss erzeugt werden
                    const taxNew = {
                        'tax':  '7.00',
                        'regularAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
                        'regularTax': Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
                        'actualTax': Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2)),
                    };
                    taxes.push(taxNew)
                }
                else{
                    taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
                    taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2))).toFixed(2));
                    taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
                    taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2))).toFixed(2));
                }
            }


            // Jetzt addiere noch den Versand
            tax = taxes.findIndex(item => item.tax == shippingTax);
            if(tax == -1){
                //Versand existiert nicht. Muss erzeugt werden
                const taxNew = {
                    'tax':  shippingTax,
                    'regularAmount': Number(parseFloat(this.calculateShippingBrutto()).toFixed(2)),
                    'regularTax': Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2)),
                    'percentOfSubtotal': 0,
                    'actualAmount': Number(parseFloat(this.calculateShippingBrutto()).toFixed(2)),
                    'actualTax': Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2)),
                };
                taxes.push(taxNew)
            }
            else{
                taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateShippingBrutto()).toFixed(2))).toFixed(2));
                taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
                taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateShippingBrutto()).toFixed(2))).toFixed(2));
                taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            }
            // console.log(taxes);
            return taxes;
        },

        //Berechne nun den Gutschein
        calculateTotalVoucher(){
            var sum = 0;
            if(this.order.vouchers.length >= 1){
                const total = this.calculateTotalBrutto();
                if(this.order.vouchers[0].rest_amount >= total){
                    sum = parseFloat(total).toFixed(2);
                }
                else{
                    sum = parseFloat(this.order.vouchers[0].rest_amount).toFixed(2);
                }
            }
            return parseFloat(sum);
        },


        generateTotal(){
            this.order.deposit = [];
            this.order.tax = [];
            this.order.heavy = [];
            this.order.fragile = [];
            this.order.total_brutto = 0;
            this.order.total_netto = 0;
            this.order.total_pledge = 0;
            this.order.total_cargo = 0;
            this.order.total_cool = 0;
            this.order.total_fragile = 0;
            this.order.total_discount = 0;
            this.order.zwischensumme = 0;
            var cool_value = 0;
            var cool_tax = 0;
            
            this.params.articles.forEach(function(item) {
                var deposit_value = 0;
                var deposit_tax = 0;
                var heavy_value = 0;
                var heavy_tax = 0;
                var fragile_value = 0;
                var fragile_tax = 0;

                // 1) Prüfe ob dies überhaupt ein Artikel ist
                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){

                    //################Bruchgut Prüfung###################
                    //Prüfe ob der Artikel Sperrgutzuschlag hat
                    if(item.fragile == 1)
                    {
                        //Artikel hat Sperrgut. Prüfe ob Sperrgut berechnet wird
                        var fragile = 0;
                        if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                        {
                            fragile = 1;
                        }

                        if(fragile == 1 && this.params.useFragile == true)
                        {
                            //Berechne Sperrgut.
                            var fragile_amount = this.order.fragile.find(i => i.type == 'Bruchgut_' + item.tax_rate);
                            if(fragile_amount == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var fragile_entry = {
                                    type: 'Bruchgut_'+item.tax_rate,
                                    amount: 4.5 * item.quantity, //((6 / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity,
                                    tax: item.tax_rate,
                                };

                                this.order.fragile.push(fragile_entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                fragile_amount.amount += (4.5 * item.quantity);
                            }

                            fragile_value = ((4.5 * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            fragile_tax = ((4.5 * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);

                        }

                    }
                    //################Ende Bruchgut#####################

                    //################Sperrgut Prüfung###################
                    //Prüfe ob der Artikel Sperrgutzuschlag hat
                    if(item.heavy == 1)
                    {
                        //Artikel hat Sperrgut. Prüfe ob Sperrgut berechnet wird
                        var cargo = 0;
                        if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                        {
                            cargo = 1;
                        }

                        if(cargo == 1 && this.params.useCargo == true)
                        {
                            //Berechne Sperrgut.
                            var heavy = this.order.heavy.find(i => i.type == 'Sperrgut_' + item.tax_rate);
                            if(heavy == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var heavy_entry = {
                                    type: 'Sperrgut_'+item.tax_rate,
                                    amount: 6.9 * item.quantity, //((6 / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity,
                                    tax: item.tax_rate,
                                };

                                this.order.heavy.push(heavy_entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                heavy.amount += (6.9 * item.quantity);
                            }

                            heavy_value = ((6.9 * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            heavy_tax = ((6.9 * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);

                        }

                    }
                    //################Ende Sperrgut#####################

                    //Prüfe ob dieser Artikel Pfand hat. Wenn ja muss Pfand berechnet werden
                    if(item.deposit > 0)
                    {
                        //Artikel hat Pfand
                        var string = item.deposit_type + '_' + item.tax_rate;

                        //Prüfe ob es schon ein Pfand Eintrag gibt hierzu.
                        var deposit = this.order.deposit.find(i => i.type == string);
                        if(deposit == undefined)
                        {
                            //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                            var entry = {
                                type: string,
                                amount: item.deposit * item.quantity,
                                tax: item.tax_rate,
                            };

                            this.order.deposit.push(entry);
                        }
                        else
                        {
                            //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                            deposit.amount += (item.deposit * item.quantity);
                        }

                        // Der Artikel hat Pfand. Der Pfandpreis muss also addiert werden.
                        // Prüfe ob der Kunde ein Firmenkunde oder Privatkunde ist.
                        

                        if(this.params.customer == "" || this.params.customer.group.tax == 1)
                        {
                            //Es ist ein Privatkunde
                            //Bei Privatkunden ist der Pfand inkl. MwSt. Also muss der Pfand umgerechnet werden nach Netto
                            deposit_value = ((item.deposit * item.quantity) / (100 + parseFloat(item.tax_rate))) * 100;
                            deposit_tax = ((item.deposit * item.quantity) / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate);
                        }

                        else
                        {
                            //Es ist ein Firmenkunde
                            //Bei Firmenkunden ist der Pfand zzgl Mwst. Pfand ist = Netto. Es muss nur dir anfallende Steuer berechnet werden
                            deposit_value = (item.deposit * item.quantity);
                            deposit_tax = ((item.deposit * item.quantity) / 100) * parseFloat(item.tax_rate);
                        }

                    }

                    //this.order.total_brutto += (item.quantity * item.price);
                    var netto_value = (((item.price / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity);
                    var tax_value = (((item.price / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate)) * item.quantity);

                    var new_brutto = 0;
                    var new_netto = 0;
                    var new_tax = 0;
                    var new_discount = 0;
                    var new_discount_tax = 0;

                    //Prüfe ob der Artikel reduziert wird.
                    if(this.order.discount > 0 && this.params.useDiscount == true && item.discountable == true)
                    {
                        //Es gibt einen Rabatt und dieser soll auch benutzt werden.
                        //Prüfe ob es ein Firmen oder Privatkunde ist

                        if(this.params.customer == "" || this.params.customer.group.tax == 1)
                        {
                            //Es ist ein Privatkunde
                            //1) Ziehe 20% vom Brutto ab
                            new_brutto = (item.price / 100) * (100 - parseFloat(this.order.discount));
                            
                            new_netto = (((new_brutto / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity);
                            new_tax = (((new_netto / (100)) * parseFloat(item.tax_rate)));
                            new_discount = netto_value - new_netto;
                            new_discount_tax = tax_value - new_tax;
                            // console.log('newBrutto=' + new_brutto );
                            // console.log('newNetto=' + new_netto );
                            // console.log('newtax=' + new_tax );
                            // console.log('newDiscount=' + new_discount );
                            // console.log('newDiscountTax=' + new_discount_tax );
                        }
                        else
                        {
                            //Firmenkunde
                            //Ziehe den Rabatt vom Netto ab
                            new_netto = (netto_value / 100) * (100 - parseFloat(this.order.discount));
                            new_tax = (((new_netto / (100 )) * parseFloat(item.tax_rate)));
                            new_discount = netto_value - new_netto;
                            new_discount_tax = tax_value - new_tax;
                        }

                        this.order.total_discount += new_discount + new_discount_tax;
                    }



                    this.order.total_netto += netto_value + deposit_value + heavy_value + fragile_value - new_discount;
                    this.order.zwischensumme += netto_value + tax_value;

                    var tax = this.order.tax.find(i => i.type == item.tax_rate);
                    if(tax == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_tax = {
                            type: item.tax_rate,
                            amount: tax_value + deposit_tax + heavy_tax + fragile_tax - new_discount_tax,
                        };

                        this.order.tax.push(entry_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax.amount += tax_value + deposit_tax + heavy_tax + fragile_tax - new_discount_tax;
                    }
                }

                else if(item.type == 'deposit_sale')
                {
                    //Es handelt sich um einen Pfandverkauf. Also muss MwSt wieder nach Kundengruppe berechnet werden.
                    if(this.params.customer == "" || this.params.customer.group.tax == 1)
                    {
                        //Es ist ein Privatkunde
                        //Bei Privatkunden ist der Pfand inkl. MwSt. Also muss der Pfand umgerechnet werden nach Netto
                        deposit_value = ((item.price / (100 + parseFloat(item.tax_rate))) * 100) * item.quantity;
                        deposit_tax = ((item.price / (100 + parseFloat(item.tax_rate))) * parseFloat(item.tax_rate)) * item.quantity;
                    }

                    else
                    {
                        //Es ist ein Firmenkunde
                        //Bei Firmenkunden ist der Pfand zzgl Mwst. Pfand ist = Netto. Es muss nur dir anfallende Steuer berechnet werden
                        deposit_value = item.price * item.quantity;
                        deposit_tax = ((item.price / 100) * parseFloat(item.tax_rate)) * item.quantity;
                    }

                    this.order.total_netto += deposit_value;

                    //Addiere noch die Steuer zu dem Pfandverkauf. Suche dafür ob es den Steuersatz schon gibt. Wenn ja addieren sonst anlegen
                    var tax_deposit = this.order.tax.find(i => i.type == item.tax_rate);
                    if(tax_deposit == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_deposit_tax = {
                            type: item.tax_rate,
                            amount: deposit_tax,
                        };

                        this.order.tax.push(entry_deposit_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_deposit.amount += deposit_tax;
                    }


                    //Addiere jetzt den Pfand zum GesamtPfand 
                    var string_type = item.deposit_type + '_' + item.tax_rate;

                    //Prüfe ob es schon ein Pfand Eintrag gibt hierzu.
                    var search = this.order.deposit.find(i => i.type == string_type);
                    if(search == undefined)
                    {
                        //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                        var entry_new = {
                            type: string_type,
                            tax: item.tax_rate,
                            amount: item.price * item.quantity
                        };

                        this.order.deposit.push(entry_new);
                    }
                    else
                    {
                        //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                        search.amount += item.price * item.quantity;
                    }
                }

                else{
                    return;
                }
            }, this);


            //Prüfe und Berechne hier noch den Kühlzuschlag
            if(this.hasCool() == true)
            {
                if(parseInt(this.params.shipping_address.zipcode) < 57000 || parseInt(this.params.shipping_address.zipcode) > 59999)
                {
                    var cool = 1;
                }

                if(cool == 1 && this.params.useCool == true)
                {
                    //Kühlzuschlag muss mit berechnet werden.
                    cool_value = (4.5 / 107) * 100;
                    cool_tax = (4.5 / 107) * 7;

                    this.order.total_netto += cool_value;
                    var tax_cool = this.order.tax.find(i => i.type == '7.00');
                    if(tax_cool == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_deposit_tax = {
                            type: '7.00',
                            amount: cool_tax,
                        };

                        this.order.tax.push(entry_deposit_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_cool.amount += cool_tax;
                    }
                    this.order.total_cool = 4.5;
                }

                

            }

            
            


            //Berechne den Gesamtpfand der im Warenkorb liegt
            var pledge = 0;
            this.order.deposit.forEach(function(item) {
                pledge += item.amount;
            });

            this.order.total_pledge = pledge;

            //Berechne den Gesamtsperrgut der im Warenkorb liegt
            var heavy_data = 0;
            this.order.heavy.forEach(function(item) {
                heavy_data += item.amount;
            });

            this.order.total_cargo = heavy_data;


            //Berechne den Gesamtbruchgut der im Warenkorb liegt
            var fragile_data = 0;
            this.order.fragile.forEach(function(item) {
                fragile_data += item.amount;
            });

            this.order.total_fragile = fragile_data;



            //##################Berechne den Versand####################
            
            //Prüfe ob über 59Euro
            const dispatch = this.filterDispatches().find(item => item.id == this.params.dispatch_id);
            if(this.order.zwischensumme < dispatch.shippingfree)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    // Es müssen Versandkosten berechnet werden
                    // Ermittle zuerst den höchsten Steuersatz.
                    var shipping_tax_rate = '7.00';

                    if(this.order.tax.length > 0)
                    {
                        var rate = Math.max.apply(Math, this.order.tax.map(function(o) { return o.amount }))
                        shipping_tax_rate = this.order.tax.find(i => i.amount == rate).type;
                    }

                    //Berechne nun die Netto Versandkosten und die Steuer der Versandkosten

                    var shipping_value = (this.params.shippingCost / (100 + parseFloat(shipping_tax_rate))) * 100; //(4 / 119) * 100;
                    var shipping_tax = (this.params.shippingCost / (100 + parseFloat(shipping_tax_rate))) * parseFloat(shipping_tax_rate);

                    this.order.total_netto += shipping_value;
                    var tax_shipping = this.order.tax.find(i => i.type == shipping_tax_rate);
                    if(tax_shipping == undefined)
                    {
                        //Es gibt noch keinen Steuersatz hierfür
                        var entry_shipping_tax = {
                            type: shipping_tax_rate,
                            amount: shipping_tax,
                        };

                        this.order.tax.push(entry_shipping_tax);
                    }

                    else
                    {
                        //Addiere den Steuersatz
                        tax_shipping.amount += shipping_tax;
                    }
                    this.order.total_shipping = this.params.shippingCost;
                    this.order.total_shipping_net = shipping_value;
                    this.order.total_shipping_tax = shipping_tax_rate;

                }
                else{
                    this.order.total_shipping = 0;
                }
            }

            else{
                this.order.total_shipping = 0;
            }


            //################Ende Versand################################


            //Errechne nun den Gesamt Brutto aus dem Netto und den einzelnen Steuersätzen
            var brutto = this.order.total_netto;
            this.order.tax.forEach(function(item) {
                brutto += item.amount;
            }, brutto);

            this.order.total_brutto = brutto;


            
        },

        showArticleModal(){
            this.$bvModal.show("articleModal");
        },

        handleEditRow(index) {
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },

        fetchCustomers(search, loading)
        {
            loading(true);
            this.search = search;
            this.getCustomers();
            loading(false);
        },

        fetchArticles(search, loading)
        {
            loading(true);
            this.search = search;
            this.getArticles();
            loading(false);
        },

        getCustomers(page = 1){
            this.$Progress.start();
            this.axios
                .get("/customers?company_id=" + this.params.company_id + '&search=' + this.search + '&page=' + page)
                .then((response) => {
                    this.customers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getArticles(){
            this.$Progress.start();
            this.axios
                .get("/articles?search=" + this.search)
                .then((response) => {
                    this.articles = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getTaxes(){
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.params.company_id = this.companies[0].id;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getStores(){
            this.$Progress.start();
            this.axios
                .get("/stores")
                .then((response) => {
                    this.stores = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.getCompanies();
        this.getPayments();
        this.getDispatches();
        this.getTaxes();
        this.getStores(); 
        //this.getCustomers();

        if(this.$route.params.id != undefined)
        {
            //Es wurde ein Kunde übergeben.
            // Laden den Kunden und setzte alles rein. 
            this.$Progress.start();
            this.axios
                .get("/customers/" + this.$route.params.id)
                .then((response) => {
                    var customer = response.data.data;
                    this.$Progress.finish();

                    this.setCustomer(customer);
                    this.params.company_id = customer.company_id;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    }

}
</script>